import React, { useState } from "react"
import { Link } from "gatsby"
import parse from "html-react-parser"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Layout from "../layout"
import Seo from "../seo"
import StaticBG from "../../assets/images/pages/support-hero.jpg"

// Sponsor Logos
import imgArtgov from "../../assets/images/uploads/support-sponsor-artsgov.png"
import imgBlick from "../../assets/images/uploads/support-sponsor-blick.png"
import imgBloomberg from "../../assets/images/uploads/support-sponsor-bloomberg.png"
import imgCommand from "../../assets/images/uploads/support-sponsor-command.png"
import imgHerblock from "../../assets/images/uploads/support-sponsor-herblock.png"
import imgJayPritzker from "../../assets/images/uploads/support-sponsor-jaypritzkerfoundation.png"
import imgNyc from "../../assets/images/uploads/support-sponsor-nyc.png"
import imgNylife from "../../assets/images/uploads/support-sponsor-nylife.png"
import imgNytimes from "../../assets/images/uploads/support-sponsor-nytimes.png"
import imgScholastic from "../../assets/images/uploads/support-sponsor-scholastic.png"
import imgMrrfund from "../../assets/images/uploads/support-sponsor-mrrfund.png"
import imgGFF from "../../assets/images/uploads/support-sponsor-gff.png"
import imgLBP from "../../assets/images/uploads/support-sponsor-lbp.png"
import imgQuad from "../../assets/images/uploads/support-sponsor-quad.png"
import imgCCX from "../../assets/images/uploads/support-sponsor-changecx.png"
import imgGV from "../../assets/images/uploads/support-sponsor-graficaveneta.png"
// Inactive Sponsors
// import imgBradbury from "../../assets/images/uploads/support-sponsor-bradbury.png"
// import imgSalesforce from "../../assets/images/uploads/support-sponsor-salesforce.png"
// import imgDHIFI from "../../assets/images/uploads/support-sponsor-dhifi.png"
// import imgAppleTV from "../../assets/images/uploads/support-sponsor-appletv.png"
// import imgRBC from "../../assets/images/uploads/support-sponsor-rbc.png"
// import imgMorganStanley from "../../assets/images/uploads/support-sponsor-morganstanley.png"
// import imgGolden from "../../assets/images/uploads/support-sponsor-golden.png"

// NSPP SPonsors
import imgIMLS from "../../assets/images/uploads/nspp-sponsor-imls.png"
// import imgMellon from "../../assets/images/uploads/nspp-sponsor-mellon.png"
import imgHearthland from "../../assets/images/uploads/nspp-sponsor-hearthland.png"
// import imgPoetry from "../../assets/images/uploads/nspp-sponsor-poetry.png"
import imgAcad from "../../assets/images/uploads/nspp-sponsor-Accad.png"

export default function Support({ data }) {
  const [openSection, setOpenSection] = useState(false)
  const toggleSection = () => {
    setOpenSection(!openSection)
  }

  return (
<Layout toggleSection={toggleSection}>
  <Seo
    title="Support"
    description="Empowering creative teens, celebrating youth voices: Support the Alliance today"
  />
      <nav id="nav-section"  style={openSection === true ? {display: 'block'} : null }  >

  <ul>
       
      {data.menu.menuItems.nodes.map((menuItem, i) => {
        if (menuItem.parentId) {
          return null
        }

        const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url

        return (
          <li key={i}>
            <Link key={menuItem.id} to={path}>
              {menuItem.label}
            </Link>
          </li>
        )
      })}
   

  </ul>
</nav>
  <div id="support" className="landing">
    <h2>Support</h2>
    <section
      id="landing-hero"
      style={{ backgroundImage: "url(" + StaticBG + ")" }}
    >
      <div id="landing-hero-overlay">
      Empowering creative teens, <br />celebrating youth voices: <br /> Support the Alliance today

      </div>
    </section>
    <section id="landing-callout">
      <div id="landing-callout-text">
        <p><strong>The Alliance for Young Artists &amp; Writers</strong>, presenter of the Awards, provides students with life-changing recognition, scholarships, and a platform for their voices to be heard. Your gift benefits our students, their educators, and alumni, providing them with opportunities beyond the Awards.</p>
      </div>
      <div id="landing-callout-buttons">
        <div><OutboundLink href="https://interland3.donorperfect.net/weblink/weblink.aspx?name=E4286&id=3" className="button-lg">Support Us!</OutboundLink></div>
        <div><OutboundLink href="mailto:support@artandwriting.org?subject=Message from Support Page on artandwriting.org&amp;body=Hello," className="button-sm">Questions?</OutboundLink></div>
      </div>
    </section>
    <section id="landing-features">
      <ul>
      {data.features.edges.map(feature => (
          <li key={feature.node.id}>
            <Link to={feature.node.uri}>
              <img
                src={
                  feature.node.featuredImage.node.thumb.childImageSharp.fixed.src
                }
                width="400"
              height="400"
                alt={feature.node.featuredImage.node.altText}
              />
              
              <span className="title">{feature.node.title}</span><span className="text">{parse(feature.node.excerpt)}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </section>
    <section id="support-sponsors" className="supporters-widget">
      <h3>Sponsors</h3>
      <ul>
            <li><img src={imgScholastic} alt="Scholastic" /></li>
            <li><img src={imgBlick} alt="Blick" /></li>
            <li><img src={imgNylife} alt="New York Life" /></li>
            <li><img src={imgMrrfund} alt="The Maurice R. Robinson Fund" /></li>
            <li><img src={imgCommand} alt="Command Companies" /></li>
            <li><img src={imgHerblock} alt="Herblock" /></li>
            <li><img src={imgLBP} alt="Lindenmeyr Book Publishing Papers" /></li>
            <li><img src={imgQuad} alt="Quad" /></li>
            <li><img src={imgNyc} alt="NYC Cultural Affairs" /></li>
            <li><img src={imgBloomberg} alt="Bloomberg Philanthorpies" /></li>
            {/* <li><img src={imgJayPritzker} alt="The Jay Pritzker Foundation" /></li> */}
            <li><img src={imgArtgov} alt="National Endowment for the Arts" /></li>
            {/* <li><img src={imgCCX} alt="Change CX" /></li> */}
            {/* <li><img src={imgGV} alt="Grafica Veneta" /></li> */}
            {/* <li><img src={imgGFF} alt="Garcia Family Foundation" /></li> */}
            <li><img src={imgNytimes} alt="The New York Times" /></li>
            {/* <li><img src={imgDHIFI} alt="Don't Hide It, Flaunt It" /></li> */}
            {/* <li><img src={imgRBC} alt="RBC Foundation USA" /></li> */}
            {/* <li><img src={imgMorganStanley} alt="Morgan Stanley" /></li> */}
            {/* <li><img src={imgAppleTV} alt="AppleTV" /></li> */}
            {/* <li><img src={imgGolden} alt="Golden Artist Colors" /></li> */}
      </ul>
    </section>
    <section id="nspp-sponsors" className="supporters-widget">
      <h3>National Student Poets Program Sponsors</h3>
      <div className="supporters-widget-nspp">
      <div>
              <img
                src={imgIMLS}
                alt="Institute of Museum and Library Services"
              />
            </div>
            <div>
              <img src={imgHearthland} alt="The Hearthland Foundation" />
            </div>
            {/* <div>
              <img src={imgPoetry} alt="Poetry Foundation" />
            </div> */}
            <div>
              <img src={imgAcad} alt="Academy of American Poets" />
            </div>
      </div>
    </section>
  </div>
</Layout>
  )
}







